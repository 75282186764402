import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (_route, _state) => {
  const _auth = inject(AuthService);

  if (_auth.isAuthorized().isAuth) {
    return true;
  } else {
    _auth.logout();
    return false;
  }
};
