import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@shared/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

interface ILoginResponse {
  user: IUser;
  status: {
    success: string;
    error: string | null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _router = inject(Router);
  private _api_url = environment.host;
  private _http = inject(HttpClient);

  private _isAuthorized$ = new BehaviorSubject<{
    isAuth: boolean;
    isLogin?: boolean;
  }>(
    localStorage.getItem('adm-token') &&
    localStorage.getItem('adm-token') !== 'undefined'
      ? { isAuth: true }
      : { isAuth: false }
  );
  public _isAuthorized = this._isAuthorized$.asObservable();

  public isAuthorized() {
    return this._isAuthorized$.getValue();
  }

  /**
   * The isAuth parameter is used to set user authorized
   * isLogin is set once after login to switch the user to the first tab
   * @param value { isAuth: boolean, isLogin?: boolean }
   */
  public setAuthorized(value: { isAuth: boolean; isLogin?: boolean }) {
    this._isAuthorized$.next(value);
  }

  login(data: { email: string; password: string }) {
    return this._http.post(
      `${this._api_url}/auth/loginByemail`,
      data
    ) as Observable<ILoginResponse>;
  }

  logout() {
    localStorage.removeItem('adm-token');
    localStorage.removeItem('adm-user');
    this.setAuthorized({ isAuth: false });
    this._router.navigate(['/login']);
  }
}
